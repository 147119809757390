// Custom.scss

@import "standardvariables";

$theme-colors: map-merge(

  $theme-colors,
  (
    "primary": #61A60E,
    "secondary": #6C6C6C
  )
);



//    "secondary":  $secondary,
//    "success":    $success,
//    "info":       $info,
//    "warning":    $warning,
//    "danger":     $danger,
//    "light":      $light,
//    "dark":       $dark